import { combineReducers } from '@reduxjs/toolkit';

import usersSlice from './users/users.slice';
import authSlice from './auth/auth.slice';

const rootReducer = combineReducers({
  authState: authSlice.reducer,
  users: usersSlice.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
