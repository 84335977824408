import React from 'react';
import moment from 'moment';

import { IStatement } from '../../../store/models';
import { getEvaluationStatusColor, getEvaluationStatusLabel } from '../evaluations-status';

export const getIDColumn = () => ({
  title: 'ID',
  field: 'id',
  render: (data: IStatement) => <div style={{ width: 'auto' }}>{data.id}</div>,
});

export const getEvaluationDateColumn = () => ({
  title: 'Data crearii',
  field: 'createdAt',
  render: (data: IStatement) => <div>{moment(data.createdAt).format('LLL')}</div>,
});

export const getAccidentDateColumn = () => ({
  title: 'Dată accident',
  field: 'dateTime',
  render: (data: IStatement) => <div>{data.dateTime ? moment(data.dateTime).format('LLL') : 'N/A'}</div>,
});

export const getEvaluationStatusColumn = (className: any) => ({
  title: 'Status',
  field: 'status',
  render: (data: IStatement) => (
    <div className={className} style={{ backgroundColor: getEvaluationStatusColor(data.status) }}>
      {getEvaluationStatusLabel(data.status)}
    </div>
  ),
});
