import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Toolbar, IconButton, AppBar, Avatar, Menu, Paper, ListItemIcon, Typography, MenuItem, useMediaQuery } from '@material-ui/core';
import { Menu as MenuIcon, ChevronLeft as CloseDrawerIcon, MeetingRoom as MeetingRoomIcon } from '@material-ui/icons/';
import clsx from 'clsx';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import { useDispatch, useSelector } from 'react-redux';

import PayPactLogo from '../../assets/LogoPayPact.png';
import { RootState } from '../../store/rootReducer';
import { APP_ROUTES } from '../../services/authentication/app-route';

import { HeaderProps } from './interfaces';
import { removeAuthUser } from '../../store/auth/auth.slice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logoutBtn: {
      marginLeft: 'auto',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    menuButton: {
      marginRight: 10,
    },
    title: {
      marginLeft: 10,
    },
    userLogo: {
      color: '#fff',
      backgroundColor: '#FFCD07',
    },
    logo: {
      height: '100%',
      width: 'auto',
    },
  }),
);

/**
 *
 * @param {HeaderProps} props
 * @returns {JSX.Element}
 * @constructor
 */
export const Header = (props: HeaderProps): JSX.Element => {
  const classes = useStyles();

  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const [userAnchor, setUserAnchor] = useState<null | HTMLElement>(null);
  const [userMenuOpen, toggleUserMenu] = useState(false);

  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const { navigationBarOpened, toggleNavigationBar } = props;

  const authState = useSelector((state: RootState) => state.authState);
  const user = authState.user;

  const currentLocation = location.pathname;

  const handleUserMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setUserAnchor(event.currentTarget);
    toggleUserMenu(true);
  };

  const resetUserMenu = () => {
    toggleUserMenu(false);
    setUserAnchor(null);
  };

  const onLogout = () => {
    resetUserMenu();
    dispatch(removeAuthUser());
    history.push(APP_ROUTES.LOGIN);
  };

  const renderNavigationBarIcon = () => {
    if (currentLocation === APP_ROUTES.LOGIN) return <></>;

    return (
      <IconButton aria-label='open drawer' className={classes.menuButton} color='inherit' edge='start' onClick={toggleNavigationBar}>
        {!navigationBarOpened ? <MenuIcon /> : <CloseDrawerIcon />}
      </IconButton>
    );
  };

  const renderUserIcon = () => {
    const isUserLoggedIn = authState?.accessToken && user?.id;

    if (!isUserLoggedIn) return <></>;

    return (
      <>
        <IconButton aria-label='delete' className={classes.logoutBtn} onClick={handleUserMenu}>
          <Avatar className={classes.userLogo}>
            <PermIdentityIcon />
          </Avatar>
        </IconButton>
      </>
    );
  };

  const renderUserMenuContent = () => {
    const isUserLoggedIn = authState?.accessToken && user?.id;

    if (!isUserLoggedIn) return <></>;

    return (
      <Paper>
        <Menu anchorEl={userAnchor} id='simple-menu' open={userMenuOpen} style={{ marginTop: '50px' }} onClose={() => resetUserMenu()}>
          <MenuItem onClick={() => onLogout()}>
            <ListItemIcon>
              <MeetingRoomIcon fontSize='small' />
            </ListItemIcon>
            <Typography variant='inherit'>Logout</Typography>
          </MenuItem>
        </Menu>
      </Paper>
    );
  };

  return (
    <AppBar className={clsx(classes.appBar)} position='fixed'>
      <Toolbar>
        {renderNavigationBarIcon()}
        <div style={{ height: 50 }}>
          <img alt='missing' className={classes.logo} src={PayPactLogo} />
        </div>
        <Typography className={classes.title} color='inherit' component='h1' noWrap={isMobile} variant='h6'>
          Dashboard
        </Typography>
        {renderUserIcon()}
        {renderUserMenuContent()}
      </Toolbar>
    </AppBar>
  );
};
