import React, { ReactElement } from 'react';
import { List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';

import { EvaluationDetailProps } from './interfaces';

export const EvaluationPaymentDetails = (props: EvaluationDetailProps) => {
  const { evaluation } = props;

  const { name, bankName, iban, amount } = evaluation.paymentDetails;

  const getListItem = (value: any, label: string, icon: ReactElement) => {
    return (
      <ListItem style={{ padding: 'unset' }}>
        <ListItemAvatar>
          <Avatar style={{ backgroundColor: 'black' }}>{icon}</Avatar>
        </ListItemAvatar>
        <ListItemText primary={`${label}:`} secondary={value ? value : 'N/A'} />
      </ListItem>
    );
  };

  return (
    <List>
      {getListItem(name, 'Nume', <PersonPinIcon />)}
      {getListItem(bankName, 'Banca', <AccountBalanceIcon />)}
      {getListItem(iban, 'IBAN', <AccountBalanceWalletIcon />)}
      {getListItem(amount, 'Suma', <AttachMoneyIcon />)}
    </List>
  );
};
