import { USER_ROLES } from './user-roles';

export const PUBLIC = Object.values(USER_ROLES); // All users

export const EVALUATIONS_ACCESS = {
  VIEW: [USER_ROLES.ADMIN],
};

export const USERS_ACCESS = {
  VIEW: [USER_ROLES.ADMIN],
};
