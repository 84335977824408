import { APP_ROUTES } from '../../services/authentication/app-route';
import { EVALUATIONS_ACCESS, USERS_ACCESS } from '../../services/authentication/user-access-rights';

import { NavigationBarItem } from './interfaces';

export const NAVIGATION_BAR_ITEMS = [
  {
    title: 'Evaluări',
    path: APP_ROUTES.EVALUATIONS,
    access: EVALUATIONS_ACCESS.VIEW,
  },
  {
    title: 'Utilizatori',
    path: APP_ROUTES.USERS,
    access: USERS_ACCESS.VIEW,
  },
];

export const getNavigationBarItemsByRole = (role: string) =>
  NAVIGATION_BAR_ITEMS.filter((item: NavigationBarItem) => item.access.includes(role));
