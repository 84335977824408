import React from 'react';
import { Divider, List, ListItem, ListItemIcon, ListItemText, Tooltip, makeStyles, Theme, createStyles } from '@material-ui/core';
import { Group as UserIcon, Dashboard as DashboardIcon } from '@material-ui/icons';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { RootState } from '../../store/rootReducer';
import { APP_ROUTES } from '../../services/authentication/app-route';
import { history } from '../../store/store';

import { getNavigationBarItemsByRole } from './navigation-bar-items';
import { NavigationBarItem, NavigationBarProps } from './interfaces';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawerHeader: { ...theme.mixins.toolbar },
  }),
);

export const NavigationBar = (props: NavigationBarProps) => {
  const classes = useStyles();

  const { opened } = props;

  const location = useLocation();

  const authUser = useSelector((state: RootState) => state.authState.user) as any;

  const items = getNavigationBarItemsByRole(authUser?.role);

  const currentRoute = location.pathname;

  const getItemColor = (item: NavigationBarItem) => (item.path === currentRoute ? 'primary' : 'inherit');

  const renderItem = (item: NavigationBarItem) => {
    switch (item.path) {
      case APP_ROUTES.EVALUATIONS:
        return <DashboardIcon color={getItemColor(item)} />;
      case APP_ROUTES.USERS:
        return <UserIcon color={getItemColor(item)} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className={classes.drawerHeader} />
      <Divider />
      <List>
        {items.map((item: NavigationBarItem) => (
          <Tooltip key={item.path} aria-label='list-item' disableHoverListener={opened} title={item.title}>
            <ListItem color='primary' selected={currentRoute === item.path} button onClick={() => history.push(item.path)}>
              <ListItemIcon>{renderItem(item)}</ListItemIcon>
              <ListItemText primary={item.title} />
            </ListItem>
          </Tooltip>
        ))}
      </List>
    </div>
  );
};
