import { AppDispatch, AppThunk } from '../store';

import { createUserRequest, updateUserRequest, usersRequest } from './users.api';
import { setUsers } from './users.slice';
import { IUser } from './users.model';

export const fetchUsersAction =
  (onSuccess?: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const response = await usersRequest();
      dispatch(setUsers(response.data));

      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      console.log('Error: ', e);
    }
  };

export const createUserAction =
  (payload: IUser, onSuccess?: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const response = await createUserRequest(payload);

      if (response) {
        dispatch(fetchUsersAction());
      }

      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      console.log('Error: ', e);
    }
  };

export const updateUserAction =
  (payload: IUser, onSuccess?: () => void): AppThunk =>
  async (dispatch: AppDispatch) => {
    try {
      const response = await updateUserRequest(payload);

      if (response) {
        dispatch(fetchUsersAction());
      }

      if (onSuccess) {
        onSuccess();
      }
    } catch (e) {
      console.log('Error: ', e);
    }
  };
