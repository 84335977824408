import { AxiosResponse } from 'axios';

import { IStatement } from '../models';
import { request } from '../../services/api/request';
import { PaginationResponse } from '../../globals/types/general';

export function getEvaluationsFile(id: number): Promise<AxiosResponse<string>> {
  return request().get(`/attachments/file/${id} `);
}

export function getFilteredEvaluations(
  payload: any,
  page: number,
  limit: number,
): Promise<AxiosResponse<PaginationResponse<IStatement>>> {
  const url = `/statements/filter?page=${page}&limit=${limit}`;

  return request().post(url, payload);
}

export function getStatementsRequest(): Promise<AxiosResponse<IStatement[]>> {
  const url = `/statements`;
  return request().get(url);
}

export function updateStatementRequest(payload: IStatement | any): Promise<AxiosResponse<PaginationResponse<IStatement>>> {
  const url = `/statements/${payload.id}`;
  return request().post(url, payload);
}

// TODO: add other status endpoints

export function adminSendsEvaluationRequest({ statementId, formData }: {statementId: number, formData?: FormData}): Promise<AxiosResponse<PaginationResponse<IStatement>>> {
  const url = `/statements/${statementId}/admin/evaluation/send`;
  return request().post(url, formData);
}

export function adminFinishNotification(statementId: number): Promise<AxiosResponse<PaginationResponse<IStatement>>> {
  const url = `/statements/${statementId}/admin/finish/notification`;
  return request().post(url);
}
