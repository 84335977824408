import React from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';

import { IStatement } from '../../../store/models';
import { updateStatementRequest } from '../../../store/statements/statements.api';
import { STATEMENT_STATUSES } from '../../../store/statements/statements.constants';

interface IProps {
  setIsOpen: (open: boolean) => void;
}

const useStyles = makeStyles(() => ({
  container: {
    padding: '20px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    flexWrap: 'wrap',
    height: 'auto',
    overflowY: 'scroll',
  },
  button: {
    height: 'auto',
    borderRadius: '25px',
    width: '120px',
    flexWrap: 'wrap',
  },
  containerButtons: {
    display: 'flex',
    justifyContent: 'space-evenly',
    alignItems: 'space-evenly',
  },
}));

export const StatementFinishDialog = ({selectedEvaluation, onClose}: {selectedEvaluation: IStatement, onClose: () => void}): JSX.Element => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const finalizeStatement = async () => {
    // TODO: call finish/paid statement endpoint
    // await updateStatementRequest({
    //   ...selectedEvaluation,
    //   status: STATEMENT_STATUSES.STATEMENT_PAID
    // })

    // mutate
  };

  return (
    <div className={classes.container}>
      <Typography style={{ marginBottom: 20 }}>
        Confirmi plata efectuata catre {selectedEvaluation?.paymentDetails?.name} in suma de {selectedEvaluation?.paymentDetails?.amount} RON ?
      </Typography>
      <div className={classes.containerButtons}>
        <Button className={classes.button} color='primary' type='submit' variant='contained' onClick={finalizeStatement}>
          Confirma
        </Button>
        <Button className={classes.button} type='submit' variant='contained' onClick={() => onClose()}>
          Anuleaza
        </Button>
      </div>
    </div>
  );
};
