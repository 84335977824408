import React, { ReactElement } from 'react';
import { List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import PersonPinIcon from '@material-ui/icons/PersonPin';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import LocationCityIcon from '@material-ui/icons/LocationCity';
import DialpadIcon from '@material-ui/icons/Dialpad';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Avatar from '@material-ui/core/Avatar';


import { ContactDetailsProps } from './interfaces';
import { PERSON_TYPES } from '../../../store/statements/statements.constants';

/**
 *
 * @param {ContactDetailsProps} props
 * @returns {JSX.Element}
 * @constructor
 */
export const EvaluationContactDetails = (props: ContactDetailsProps): JSX.Element => {
  const { email, phone, insuredPhone, insuredEmail, cui, city, companyName, address, county, type } = props;

  const getPersonTypeLabel = (personType: string | null) => {
    switch (personType) {
      case PERSON_TYPES.MYSELF:
        return 'Persoană fizică';
      case PERSON_TYPES.OTHER_INDIVIDUAL:
        return 'Altă persoană fizică';
      case PERSON_TYPES.LEGAL_PERSON:
        return 'Persoană juridică';
      case PERSON_TYPES.LEASING_COMPANY:
        return 'Companie leasing';
      default:
        return 'N/A';
    }
  };

  const getListItem = (value: any, label: string, icon: ReactElement) => {
    if (!value) return <></>;

    return (
      <ListItem style={{ padding: 'unset' }}>
        <ListItemAvatar>
          <Avatar style={{ backgroundColor: 'black' }}>{icon}</Avatar>
        </ListItemAvatar>
        <ListItemText primary={`${label}:`} secondary={value} />
      </ListItem>
    );
  };

  return (
    <List>
      {getListItem(getPersonTypeLabel(type), 'Tip persoană', <PersonPinIcon />)}
      {getListItem(email, 'Email', <EmailIcon />)}
      {getListItem(phone, 'Telefon', <PhoneIcon />)}
      {getListItem(insuredPhone, 'Telefon asigurat', <PhoneIcon />)}
      {getListItem(insuredEmail, 'Email asigurat', <EmailIcon />)}
      {getListItem(companyName, 'Nume companie', <LocationCityIcon />)}
      {getListItem(cui, 'CUI', <DialpadIcon />)}
      {getListItem(county, 'Județ', <LocationOnIcon />)}
      {getListItem(city, 'Oraș', <LocationOnIcon />)}
      {getListItem(address, 'Adresă', <LocationOnIcon />)}
    </List>
  );
};
