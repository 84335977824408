import axios, { AxiosInstance } from 'axios';

const BASE_URL = process.env.REACT_APP_API_URL;

const getAuthenticationToken = () => {
  try {
    return localStorage.getItem('dashboard_access_token');
  } catch {
    return null;
  }
};

export function request(): AxiosInstance {
  const token = getAuthenticationToken();

  if (!token) {
    return axios.create({
      baseURL: BASE_URL,
    });
  }

  return axios.create({
    baseURL: BASE_URL,
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
}
