import { createTheme } from '@material-ui/core';

export const theme = createTheme({
  palette: {
    primary: {
      light: '#8052ff',
      main: '#4123D7',
      dark: '#0000a4',
      contrastText: '#E6E7F2',
    },
    secondary: {
      light: '#9d8eff',
      main: '#6360ff',
      dark: '#1135cb',
      contrastText: '#000',
    },
    error: {
      light: '#ff7762',
      main: '#db4437',
      dark: '#a2000f',
      contrastText: '#000',
    },
    success: {
      light: '#66d683',
      main: '#2da455',
      dark: '#00742a',
      contrastText: '#000',
    },
    warning: {
      light: '#ffc269',
      main: '#eb913a',
      dark: '#b46301',
      contrastText: '#000',
    },
    background: {
      default: '#f0f2fc',
      paper: '#ffffff',
    },
    action: {
      selected: '#E6E6FF',
      hover: '#f0f2fc',
    },
  },
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
});
