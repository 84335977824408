import React from 'react';
import { Card, CardContent, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { createStyles } from '@material-ui/core/styles';

import { getContactDetailsByPersonType } from '../helpers';
import { STATEMENT_TYPES } from '../../../store/statements/statements.constants';

import { EvaluationDetailProps } from './interfaces';
import { EvaluationPaymentDetails } from './evaluation-payment-details';
import { EvaluationQuestionsDetails } from './evaluation-questions-details';
import { EvaluationAccidentDetails } from './evaluation-accident-details';

const useStyles = makeStyles(() =>
  createStyles({
    content: {
      display: 'flex',
      background: 'transparent',
      width: '100%',
      justifyContent: 'center',
    },
    card: {
      background: 'transparent',
      boxShadow: 'unset',
      width: '100%',
      borderRadius: 'unset',
    },
    detailTitle: {
      fontSize: 16,
      textDecoration: 'underline',
      textAlign: 'left',
      color: 'black',
      margin: '0 0 15px 0',
    },
  }),
);

export const EvaluationDetailsPanel = (props: EvaluationDetailProps) => {
  const { evaluation, type } = props;

  const classes = useStyles();

  const renderPaymentDetails = () => {
    if (evaluation.paymentDetails) return <EvaluationPaymentDetails evaluation={evaluation} type={type} />;

    return <Typography> Utilizatorul trebuie sa continue cu următorii pași ...</Typography>;
  };

  const renderAccidentDetails = () => {
    if (type !== STATEMENT_TYPES.NOTIFICATION) return <></>;

    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography className={classes.detailTitle} gutterBottom>
            Detalii accident
          </Typography>
          <EvaluationAccidentDetails evaluation={evaluation} type={type} />
        </CardContent>
      </Card>
    );
  };

  const renderBankDetails = () => {
    if (type !== STATEMENT_TYPES.EVALUATION) return <></>;

    return (
      <Card className={classes.card}>
        <CardContent>
          <Typography className={classes.detailTitle} gutterBottom>
            Detalii bancare
          </Typography>
          {renderPaymentDetails()}
        </CardContent>
      </Card>
    );
  };

  return (
    <div className={classes.content}>
      <Card className={classes.card}>
        <CardContent>
          <Typography className={classes.detailTitle} gutterBottom>
            Date contact păgubit
          </Typography>
          {getContactDetailsByPersonType(evaluation)}
        </CardContent>
      </Card>
      {renderAccidentDetails()}
      {renderBankDetails()}
      <Card className={classes.card}>
        <CardContent>
          <Typography className={classes.detailTitle} gutterBottom>
            Alte detalii
          </Typography>
          <EvaluationQuestionsDetails evaluation={evaluation} type={type} />
        </CardContent>
      </Card>
    </div>
  );
};
