import { Dialog, Grid, IconButton, Typography } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/styles';
import { createStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from '../../../store/rootReducer';
import { getEvaluationAccidentDocuments, getEvaluationGuiltyDocuments, getEvaluationInjuredDocuments } from '../evaluation-documents';
import { EvaluationDocumentsDialogProps, Photo } from '../interfaces';
import { PreviewImage } from '../../general/preview-image';

import { EvaluationDocumentsList } from './evaluation-documents-list';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      margin: 0,
      padding: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
    dialogContent: {
      justifyContent: 'space-between',
      padding: '2%',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }),
);

/**
 *
 * @param {EvaluationDocumentsDialogProps} props
 * @returns {JSX.Element}
 * @constructor
 */
export const EvaluationDocumentsDialog = (props: EvaluationDocumentsDialogProps): JSX.Element => {
  const classes = useStyles();

  const { selectedEvaluation, onClose } = props;
  const { accessToken } = useSelector((state: RootState) => state.authState);

  const [previewItem, setPreviewItem] = useState<Photo | null>(null);

  const renderContent = () => {
    if (previewItem) {
      return <PreviewImage src={previewItem.src} title={previewItem.title} onClose={() => setPreviewItem(null)} />;
    }

    return (
      <Dialog aria-labelledby='customized-dialog-title' maxWidth='lg' fullWidth open onClose={onClose}>
        <MuiDialogTitle className={classes.dialogTitle} disableTypography>
          <Typography variant='h6'>Documente</Typography>
          <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        <Grid className={classes.dialogContent} direction='row' container>
          <Grid sm={3} item>
            <EvaluationDocumentsList
              handlePreview={(item: Photo) => setPreviewItem(item)}
              photos={getEvaluationInjuredDocuments(selectedEvaluation, accessToken)}
              title='Documente pagubit'
            />
          </Grid>
          <Grid sm={3} item>
            <EvaluationDocumentsList
              handlePreview={(item: Photo) => setPreviewItem(item)}
              photos={getEvaluationGuiltyDocuments(selectedEvaluation, accessToken)}
              title='Documente vinovat'
            />
          </Grid>
          <Grid sm={3} item>
            <EvaluationDocumentsList
              handlePreview={(item: Photo) => setPreviewItem(item)}
              photos={getEvaluationAccidentDocuments(selectedEvaluation, accessToken)}
              title='Documente accident'
            />
          </Grid>
        </Grid>
      </Dialog>
    );
  };

  return renderContent();
};
