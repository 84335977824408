import { Dialog, IconButton, ImageList, ImageListItem, ImageListItemBar, Typography } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles } from '@material-ui/styles';
import { createStyles, Theme } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Visibility as PreviewIcon } from '@material-ui/icons';

import { RootState } from '../../../store/rootReducer';
import { PhotoProofModel } from '../../../store/models';
import { getAttachmentSrc } from '../../../store/statements/statements.constants';
import { PreviewImage } from '../../general/preview-image';
import { ImageContent } from '../../general/image-content';
import { EvaluationPhotosDialogProps } from '../interfaces';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      margin: 0,
      padding: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    imageTitle: {
      background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    preview: {
      color: 'white',
    },
    imagesList: {
      padding: 10,
    },
    imgContent: {
      width: '100%',
      position: 'absolute',
    },
  }),
);

export const EvaluationPhotosDialog = (props: EvaluationPhotosDialogProps) => {
  const classes = useStyles();

  const { selectedEvaluation, onClose } = props;
  const { accessToken } = useSelector((state: RootState) => state.authState);

  const [previewItem, setPreviewItem] = useState<PhotoProofModel | null>(null);

  const renderImageList = () => {
    if (!selectedEvaluation?.injured?.photoProofs) return <></>;

    return (
      <ImageList className={classes.imagesList} cols={4} gap={6}>
        {selectedEvaluation.injured.photoProofs.map((item: PhotoProofModel) => (
          <ImageListItem key={item.id.toString()}>
            <ImageContent className={classes.imgContent} src={`${getAttachmentSrc(item.attachmentId, accessToken)}`} title={item.type} />
            <ImageListItemBar
              actionIcon={
                <IconButton aria-label={`preview ${item.type}`} className={classes.preview} onClick={() => setPreviewItem(item)}>
                  <PreviewIcon />
                </IconButton>
              }
              actionPosition='left'
              className={classes.imageTitle}
              position='top'
              title={item.type}
            />
          </ImageListItem>
        ))}
      </ImageList>
    );
  };

  const renderContent = () => {
    if (previewItem) {
      return (
        <PreviewImage
          src={`${getAttachmentSrc(previewItem.attachmentId, accessToken)}`}
          title={previewItem.type}
          onClose={() => setPreviewItem(null)}
        />
      );
    }

    return (
      <Dialog aria-labelledby='customized-dialog-title' maxWidth='lg' fullWidth open onClose={onClose}>
        <MuiDialogTitle className={classes.dialogTitle} disableTypography>
          <Typography variant='h6'>Poze Daune</Typography>
          <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </MuiDialogTitle>
        {renderImageList()}
      </Dialog>
    );
  };

  return renderContent();
};
