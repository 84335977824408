import DefaultPicture from '../../assets/pictures/defaultPicture.jpeg';

export enum EVALUATION_STATUSES {
  EVALUATION_INCOMPLETE = 'EVALUATION_INCOMPLETE',
  EVALUATION_AWAITING_USER_PAYMENT = 'EVALUATION_AWAITING_USER_PAYMENT',
  EVALUATION_AWAITING_ADMIN_REVIEW = 'EVALUATION_AWAITING_ADMIN_REVIEW',
  EVALUATION_FINISHED = 'EVALUATION_FINISHED',
}

export enum STATEMENT_STATUSES {
  STATEMENT_INCOMPLETE = 'STATEMENT_INCOMPLETE',
  STATEMENT_AWAITING_ADMIN_PAYMENT = 'STATEMENT_AWAITING_ADMIN_PAYMENT',
  STATEMENT_FINISHED = 'STATEMENT_FINISHED',
}

export enum NOTIFICATION_STATUSES {
  NOTIFICATION_INCOMPLETE = 'NOTIFICATION_INCOMPLETE',
  NOTIFICATION_AWAITING_ADMIN = 'NOTIFICATION_AWAITING_ADMIN',
  NOTIFICATION_FINISHED = 'NOTIFICATION_FINISHED',
}

export enum TABLE_ACTIONS {
  DOCUMENTS = 'DOCUMENTS',
  PHOTOS = 'PHOTOS',
  EVALUATION_FINISH = 'EVALUATION_FINISH',
  NOTIFICATION_FINISH = 'NOTIFICATION_FINISH',
  STATEMENT_FINISH = 'STATEMENT_FINISH'
}

export enum STATEMENT_TYPES {
  NOTIFICATION = 'NOTIFICATION',
  EVALUATION = 'EVALUATION',
  STATEMENTS = 'STATEMENTS',
}

export const getAttachmentSrc = (id: any, token: string) => {
  if (!id) return DefaultPicture;

  return `${process.env.REACT_APP_API_URL}/attachments/file/${id}?token=${token}`;
};

export const EVALUATIONS_DOCUMENTS_LABEL = {
  IDENTITY_CARD: 'Card de identitate',
  DRIVING_LICENSE: 'Permis de conducere',
  INSURANCE: 'Asigurare',
  CAR_IDENTITY: 'Talon',
  AMICABLE_FINDING: 'Constatare amiabilă',
  POLICE_REPORT: 'Proces verbal',
  MANDATE: 'Mandat',
  DEBT_ASSIGNMENT: 'Cesiune',
  OBSERVATION_NOTE: 'Nota constatare',
  COMPENSATION_OFFER: 'Oferta despagubire',
};

export enum PERSON_TYPES {
  MYSELF = 'MYSELF',
  OTHER_INDIVIDUAL = 'OTHER_INDIVIDUAL',
  LEGAL_PERSON = 'LEGAL_PERSON',
  LEASING_COMPANY = 'LEASING_COMPANY',
}
