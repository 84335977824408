import React from 'react';

import { IStatement } from '../../store/models';

import { EvaluationContactDetails } from './table/evaluation-contact-details';
import { ContactDetailsProps } from './table/interfaces';
import { PERSON_TYPES } from '../../store/statements/statements.constants';

// TODO: move this method to the appropriate parent file/folder
export const getContactDetailsByPersonType = (evaluation: IStatement): JSX.Element => {
  const { type, phone, email, city, county, companyName, address, cui, insuredPhone, insuredEmail } = evaluation.injured;

  let props: ContactDetailsProps;

  switch (type) {
    case PERSON_TYPES.MYSELF:
      props = { type, phone, email };
      break;
    case PERSON_TYPES.OTHER_INDIVIDUAL:
      props = { type, phone, email, insuredPhone, insuredEmail };
      break;
    case PERSON_TYPES.LEGAL_PERSON:
    case PERSON_TYPES.LEASING_COMPANY:
      props = { type, phone, email, cui, address, companyName, county, city };
      break;
    default:
      return <></>;
  }

  return <EvaluationContactDetails {...props} />;
};
