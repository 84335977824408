import { Card, Dialog, IconButton, Typography } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { createStyles, Theme } from '@material-ui/core/styles';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import CloseIcon from '@material-ui/icons/Close';

import { ImageContent } from './image-content';
import { PreviewImageProps } from './interfaces';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dialogTitle: {
      margin: 0,
      padding: theme.spacing(2),
      backgroundColor: theme.palette.primary.main,
      color: 'white',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
    imgContent: {
      width: '100%',
      height: '100%',
    },
  }),
);

/**
 *
 * @param {PreviewImageProps} props
 * @returns {JSX.Element}
 * @constructor
 */
export const PreviewImage = (props: PreviewImageProps): JSX.Element => {
  const classes = useStyles();

  const { title, src, onClose } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog aria-labelledby='customized-dialog-title' maxWidth='xl' open onClose={handleClose}>
      <MuiDialogTitle className={classes.dialogTitle} disableTypography>
        <Typography variant='h6'>{title}</Typography>
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </MuiDialogTitle>
      <Card style={{ padding: '3%', overflow: 'visible', height: 'auto' }}>
        <ImageContent className={classes.imgContent} src={src} title={title} />
      </Card>
    </Dialog>
  );
};
