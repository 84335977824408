import React, { useEffect, useState } from 'react';
import { Router } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { createStyles, CssBaseline, Drawer as DrawerMui, Theme } from '@material-ui/core';
import clsx from 'clsx';

import { history } from './store/store';
import { APP_ROUTES } from './services/authentication/app-route';
import { NavigationBar } from './components/main/navigation-bar';
import { Header } from './components/main/header';
import { AppRouter } from './services/authentication/app-router';

const navBarWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    content: {
      backgroundColor: theme.palette.background.default,
      width: '100%',
      height: 'calc(100% - 56px)',
      marginTop: 56,
      [theme.breakpoints.up('sm')]: {
        height: 'calc(100% - 64px)',
        marginTop: 64,
      },
    },
    hide: {
      display: 'none',
    },
    navBar: {
      width: navBarWidth,
      flexShrink: 0,
      whiteSpace: 'nowrap',
    },
    navBarOpen: {
      width: navBarWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    navBarClose: {
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      overflowX: 'hidden',
      width: theme.spacing(7) + 1,
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(7) + 1,
      },
    },
  }),
);

export default function App() {
  const classes = useStyles();

  const myHistory = history.location.pathname;
  const [navigationBarOpened, toggleNavigationBar] = useState<boolean>(false);
  const [currentRoute, setCurrentRoute] = useState<string>(myHistory);

  useEffect(
    () =>
      history.listen(location => {
        setCurrentRoute(location.pathname);
      }),
    [myHistory],
  );

  const renderNavigationBar = () => {
    if (currentRoute === APP_ROUTES.LOGIN) return <></>;

    return (
      <DrawerMui
        className={clsx(classes.navBar, {
          [classes.navBarOpen]: navigationBarOpened,
          [classes.navBarClose]: !navigationBarOpened,
        })}
        classes={{
          paper: clsx({
            [classes.navBarOpen]: navigationBarOpened,
            [classes.navBarClose]: !navigationBarOpened,
          }),
        }}
        variant='permanent'
      >
        <NavigationBar opened={navigationBarOpened} />
      </DrawerMui>
    );
  };

  return (
    <Router history={history}>
      <div className={classes.root}>
        <CssBaseline />
        <Header navigationBarOpened={navigationBarOpened} toggleNavigationBar={() => toggleNavigationBar(!navigationBarOpened)} />
        {renderNavigationBar()}
        <div className={classes.content}>
          <AppRouter />
        </div>
      </div>
    </Router>
  );
}
