import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Container from '@material-ui/core/Container';
import MaterialTable from '@material-table/core';
import { RouteComponentProps } from 'react-router-dom';
import TextField from '@material-ui/core/TextField/TextField';
import { MenuItem, Modal, Select } from '@material-ui/core';

import { RootState } from '../../store/rootReducer';
import { fetchUsersAction, updateUserAction } from '../../store/users/users.action';
import { USER_ROLES } from '../../services/authentication/user-roles';

import { AddUserModal } from './AddUserModal';

export const Users: FC<RouteComponentProps> = () => {
  const dispatch = useDispatch();
  const users = useSelector((state: RootState) => state.users.data);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    dispatch(fetchUsersAction());
  }, [dispatch]);

  return (
    <Container component='main' maxWidth='xl'>
      <MaterialTable
        actions={[
          {
            icon: 'add',
            tooltip: 'Add User',
            isFreeAction: true,
            onClick: () => {
              setIsOpen(true);
            },
          },
          {
            icon: 'refresh',
            tooltip: 'Refresh list',
            isFreeAction: true,
            onClick: () => {
              dispatch(fetchUsersAction());
            },
          },
        ]}
        columns={[
          {
            title: 'ID',
            field: 'id',
            type: 'numeric',
            align: 'left',
            editable: 'never',
            cellStyle: { width: 50 },
            headerStyle: { width: 50 },
            render: rowData => <div>{rowData.id}</div>,
          },
          {
            title: 'Email',
            field: 'email',
            render: rowData => <div>{rowData.email}</div>,
            editComponent: props => (
              <TextField
                margin='none'
                name='email'
                style={{ transform: 'scale(0.8)' }}
                value={props.value}
                variant='outlined'
                required
                onChange={e => {
                  props.onChange(e.target.value);
                }}
              />
            ),
          },
          {
            title: 'Name',
            field: 'name',
            render: rowData => <div>{rowData.name}</div>,
            editComponent: props => (
              <TextField
                margin='none'
                name='name'
                style={{ transform: 'scale(0.8)' }}
                value={props.value}
                variant='outlined'
                required
                onChange={e => {
                  props.onChange(e.target.value);
                }}
              />
            ),
          },
          {
            title: 'Role',
            field: 'role',
            render: rowData => <div>{rowData.role}</div>,
            editComponent: props => (
              <Select value={props.value} onChange={e => props.onChange(e.target.value as string)}>
                <MenuItem value={USER_ROLES.ADMIN}>ADMIN</MenuItem>
              </Select>
            ),
          },
        ]}
        data={users.map(x => Object.assign({}, x))}
        editable={{
          onRowUpdate: async newData => dispatch(updateUserAction(newData, () => new Promise(() => null))),
        }}
        title='Users'
      />
      <Modal
        open={isOpen}
        closeAfterTransition
        onClose={() => {
          setIsOpen(false);
        }}
      >
        <AddUserModal setIsOpen={setIsOpen} />
      </Modal>
    </Container>
  );
};
