import { Route, Switch } from 'react-router-dom';
import * as React from 'react';
import { createContext, useContext } from 'react';
import { io } from 'socket.io-client';

import { Users } from '../../screens/users';
import { LoginPage } from '../../pages/login-page';
import { StatementsPage } from '../../pages/statements-page';

import { EVALUATIONS_ACCESS, PUBLIC, USERS_ACCESS } from './user-access-rights';
import { APP_ROUTES } from './app-route';
import { AccessRouter } from './access-router';
import { API_PREFIX } from '../../globals/constants/global';

const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;

const SocketContext = createContext({
  socket: io(`${WEBSOCKET_URL}`, { path: `${API_PREFIX}/socket/statements` }).connect(),
});

export const useSocket = () => useContext(SocketContext);

export const AppRouter = (): JSX.Element => (
  <>
    <Switch>
      <Route component={LoginPage} path={APP_ROUTES.LOGIN} exact />
      <AccessRouter Component={StatementsPage} path={APP_ROUTES.EVALUATIONS} roles={EVALUATIONS_ACCESS.VIEW} exact />
      <AccessRouter Component={Users} path={APP_ROUTES.USERS} roles={USERS_ACCESS.VIEW} exact />
      <AccessRouter exact={false} path='*' roles={PUBLIC} />
    </Switch>
  </>
);
