import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AuthStateModel } from '../models';

const initialState: AuthStateModel = {
  accessToken: '',
  user: null,
};

const authSlice = createSlice({
  name: 'authState',
  initialState,
  reducers: {
    setAuthUser(state, action: PayloadAction<AuthStateModel>) {
      const payload = action.payload;

      const accessToken = payload.accessToken;
      localStorage.setItem('dashboard_access_token', accessToken);

      return payload;
    },
    removeAuthUser(state) {
      localStorage.removeItem('dashboard_access_token');

      return initialState;
    },
  },
});

export default authSlice;

export const { setAuthUser, removeAuthUser } = authSlice.actions;
