import { IconButton, ImageList, ImageListItem, ImageListItemBar, Typography } from '@material-ui/core';
import { Visibility as PreviewIcon } from '@material-ui/icons';
import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { createStyles } from '@material-ui/core/styles';

import { Photo, EvaluationDocumentsListProps } from '../interfaces';
import { ImageContent } from '../../general/image-content';

const useStyles = makeStyles(() =>
  createStyles({
    listTitle: {
      overflow: 'visible',
      color: 'black',
    },
    imageTitle: {
      height: '25%',
      background: 'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
    },
    preview: {
      color: 'white',
    },
    imgContent: {
      width: '100%',
      position: 'absolute',
    },
  }),
);

export const EvaluationDocumentsList = (props: EvaluationDocumentsListProps) => {
  const classes = useStyles();

  const { title, photos, handlePreview } = props;

  return (
    <>
      <Typography align='center' className={classes.listTitle} color='primary' variant='h6' noWrap>
        {title}
      </Typography>
      <ImageList cols={1} gap={6}>
        {photos.map((item: Photo) => (
          <ImageListItem key={Math.floor(Math.random() * 9999)}>
            <ImageContent className={classes.imgContent} src={`${item.src}`} title={item.title} />
            <ImageListItemBar
              actionIcon={
                <IconButton aria-label={`preview ${item.title}`} className={classes.preview} onClick={() => handlePreview(item)}>
                  <PreviewIcon />
                </IconButton>
              }
              actionPosition='left'
              className={classes.imageTitle}
              position='top'
              title={item.title}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </>
  );
};
