import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField/TextField';
import Button from '@material-ui/core/Button';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { InputAdornment, makeStyles, MenuItem, Select } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import { Visibility, VisibilityOff } from '@material-ui/icons';

import { createUserAction } from '../../store/users/users.action';
import { IUser } from '../../store/users/users.model';
import { USER_ROLES } from '../../services/authentication/user-roles';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  buttonWrapper: {
    position: 'relative',
  },
  form: {
    width: '50%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -10,
    marginLeft: -12,
  },
}));

interface IModal {
  setIsOpen: (value: boolean) => void;
}

const ERROR_MESSAGE = 'Passwords does not match';

export const AddUserModal = ({ setIsOpen }: IModal): JSX.Element => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [role, setRole] = useState<string>('');

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword(!showPassword);
  const handleMouseDownPassword = () => setShowPassword(!showPassword);

  const createUser = async () => {
    const user: IUser = {
      email,
      name,
      password,
      role,
    };
    dispatch(createUserAction(user, () => setIsOpen(false)));
  };

  return (
    <div
      style={{
        padding: 20,
        position: 'absolute',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        background: '#EEEEEE',
        boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
        alignSelf: 'center',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        width: '60%',
        height: '70%',
        top: '10%',
        overflowY: 'scroll',
      }}
    >
      <div className={classes.form}>
        <TextField
          id='email'
          label='Email'
          margin='normal'
          name='email'
          value={email}
          variant='outlined'
          autoFocus
          fullWidth
          required
          onChange={e => setEmail(e.target.value)}
        />
        <TextField
          id='name'
          label='Name'
          margin='normal'
          name='name'
          value={name}
          variant='outlined'
          fullWidth
          required
          onChange={e => setName(e.target.value)}
        />
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          autoComplete='current-password'
          id='password'
          label='Password'
          margin='normal'
          name='password'
          type={showPassword ? 'text' : 'password'}
          value={password}
          variant='outlined'
          fullWidth
          required
          onChange={e => setPassword(e.target.value)}
        />
        <TextField
          id='confirmPassword'
          label='Confirm Password'
          margin='normal'
          name='confirmPassword'
          value={confirmPassword}
          variant='outlined'
          fullWidth
          required
          onChange={e => setConfirmPassword(e.target.value)}
        />
        <Select value={role} onChange={e => setRole(e.target.value as string)}>
          <MenuItem value={USER_ROLES.ADMIN}>ADMIN</MenuItem>
        </Select>
        {confirmPassword && password !== confirmPassword && (
          <Typography color='error' display='block' variant='caption'>
            {ERROR_MESSAGE}
          </Typography>
        )}
        <div className={classes.buttonWrapper}>
          <Button className={classes.submit} color='primary' type='submit' variant='contained' fullWidth onClick={() => createUser()}>
            Create User
          </Button>
        </div>
      </div>
    </div>
  );
};
