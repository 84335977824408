import { RouteComponentProps, Route, Redirect } from 'react-router-dom';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getNavigationBarItemsByRole } from '../../components/main/navigation-bar-items';
import { RootState } from '../../store/rootReducer';

import { APP_ROUTES } from './app-route';
import { AccessRouterProps } from './interfaces';
import { removeAuthUser } from '../../store/auth/auth.slice';

export const AccessRouter = (props: AccessRouterProps): JSX.Element => {
  const { Component, path, roles, exact } = props;

  const dispatch = useDispatch();

  const authState = useSelector((state: RootState) => state.authState);
  const user = authState.user;

  const hasAccess = () => {
    if (authState?.accessToken && user?.role) return roles.includes(user.role);

    return false;
  };

  /**
   *
   * @param {} componentProps
   * @returns {JSX.Element}
   */
  const render = (componentProps: RouteComponentProps) => {
    const isLoggedIn = !!authState?.accessToken;

    if (hasAccess() && Component) return <Component {...componentProps} />;

    let redirectPath = APP_ROUTES.LOGIN;

    const userRole = user?.role;

    if (isLoggedIn && userRole) {
      const navBarItems = getNavigationBarItemsByRole(userRole);

      if (navBarItems?.length) {
        const firstNavBarItem = navBarItems[0];

        redirectPath = firstNavBarItem.path;
      } else dispatch(removeAuthUser());
    } else dispatch(removeAuthUser());

    return (
      <Redirect
        to={{
          pathname: redirectPath,
          state: {
            requestedPath: path,
          },
        }}
      />
    );
  };

  return <Route exact={exact} path={path} render={(componentProps: RouteComponentProps) => render(componentProps)} />;
};
