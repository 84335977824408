import React, { useState, useCallback, useEffect } from 'react';
import {
  Theme,
  createStyles,
  Container,
  CssBaseline,
  Avatar,
  Typography,
  TextField,
  InputAdornment,
  IconButton,
  Button,
  CircularProgress,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';

import { getNavigationBarItemsByRole } from '../components/main/navigation-bar-items';
import { setAuthUser } from '../store/auth/auth.slice';
import { signInRequest } from '../store/auth/auth.api';
import { AuthUserState } from '../store/auth/auth.model';
import { WRONG_USER_OR_PASSWORD } from '../globals/constants/global';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      marginTop: theme.spacing(8),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.secondary.main,
    },
    buttonWrapper: {
      position: 'relative',
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
    },
    submit: {
      margin: theme.spacing(3, 0, 2),
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -10,
      marginLeft: -12,
    },
  } as any), //TODO: fix any
);

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export const LoginPage = (): JSX.Element => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [showPassword, setShowPassword] = useState(false);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const redirectUserAfterSuccessLogin = (data: AuthUserState) => {
    const accessToken = data?.accessToken;
    const userData = data?.user;
    const userRole = userData?.role;

    if (accessToken && userRole) {
      const navBarItems = getNavigationBarItemsByRole(userRole);

      if (navBarItems?.length <= 0) {
        setError(WRONG_USER_OR_PASSWORD);

        return;
      }

      const firstNavBarItem = navBarItems[0];

      dispatch(setAuthUser(data));
      window.location.assign(firstNavBarItem.path);
    } else setError(WRONG_USER_OR_PASSWORD);
  };

  const handleLogin = useCallback(
    () =>
      new Promise<void>((resolve, reject) => {
        if (email && password) {
          const payload = {
            email,
            password,
          };

          setLoading(true);
          setError('');

          signInRequest(payload)
            .then(response => {
              setLoading(false);
              const data = response?.data;

              redirectUserAfterSuccessLogin(data);

              resolve();
            })
            .catch(() => {
              setLoading(false);
              setError(WRONG_USER_OR_PASSWORD);

              reject();
            });
        }
      }),
    [
      // eslint-disable-line react-hooks/exhaustive-deps
      email,
      password,
    ],
  );

  useEffect(() => {
    const listener = (event: any) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') handleLogin();
    };
    document.addEventListener('keydown', listener);

    return () => document.removeEventListener('keydown', listener);
  }, [handleLogin]);

  const renderContent = () => {
    if (loading) return <CircularProgress className={classes.buttonProgress} size={24} />;

    return (
      <>
        <TextField
          autoComplete='email'
          id='email'
          label='Email'
          margin='normal'
          name='email'
          value={email}
          variant='outlined'
          autoFocus
          fullWidth
          required
          onChange={(e: any) => setEmail(e.target.value)}
        />
        <TextField
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton
                  aria-label='toggle password visibility'
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          autoComplete='current-password'
          id='password'
          label='Parola'
          margin='normal'
          name='password'
          type={showPassword ? 'text' : 'password'}
          value={password}
          variant='outlined'
          fullWidth
          required
          onChange={(e: any) => setPassword(e.target.value)}
        />
        <Typography color='error' display='block' variant='caption'>
          {error}
        </Typography>
        <div className={classes.buttonWrapper}>
          <Button
            className={classes.submit}
            color='primary'
            disabled={loading}
            type='submit'
            variant='contained'
            fullWidth
            onClick={() => handleLogin()}
          >
            Intrați în cont
          </Button>
        </div>
      </>
    );
  };

  return (
    <Container component='main' maxWidth='xs'>
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component='h1' variant='h5'>
          Autentificare
        </Typography>
        <div className={classes.form}>{renderContent()}</div>
      </div>
    </Container>
  );
};
