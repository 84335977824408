import { UNAUTHORIZED_STATUS_CODE } from '../constants/global';
import { removeAuthUser } from '../../store/auth/auth.slice';
import { APP_ROUTES } from '../../services/authentication/app-route';

export const handleRequestError = (error: any, dispatch: any) => {
  const response = error?.response;
  const data = response?.data;
  const errorMessage = data?.errorMessage || data?.message || error?.message || 'Eroare';

  if (response?.status === UNAUTHORIZED_STATUS_CODE) {
    dispatch(removeAuthUser());
    window.location.assign(APP_ROUTES.LOGIN);
  }

  return errorMessage;
};
