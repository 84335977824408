import { AxiosResponse } from 'axios';

import { request } from '../../services/api/request';

import { IUser } from './users.model';

export function usersRequest(): Promise<AxiosResponse<IUser[]>> {
  return request().get('/users/');
}

export function createUserRequest(payload: IUser): Promise<AxiosResponse<IUser>> {
  return request().post(`/users/add`, payload);
}

export function updateUserRequest(payload: IUser): Promise<AxiosResponse<IUser>> {
  return request().put('/users/update', payload);
}
