import {
  Button,
  Card,
  CardActionArea,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { createStyles, Theme } from '@material-ui/core/styles';
import React, { useCallback, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import CancelIcon from '@material-ui/icons/Cancel';

import DefaultPicture from '../../../assets/pictures/defaultPicture.jpeg';
import { EvaluationPhotosDialogProps } from '../interfaces';
import { adminSendsEvaluationRequest } from '../../../store/statements/statements.api';
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      dialogTitle: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        color: 'white',
      },
      dropZone: {
        width: '100%',
        cursor: 'pointer',
        border: '1px solid lightgray',
        borderStyle: 'dashed',
      },
      preview: {
        color: 'white',
      },
      pdfPage: {
        margin: '0 auto',
        width: '45% !important',
        height: '100% !important',
      },
      remove: {
        position: 'absolute',
        top: '2px',
        right: '2px',
        zIndex: 100,
      },
      actions: {
        margin: '20px auto 30px auto',
      },
      loadingContent: {
        width: '100%',
        height: '100%',
        display: 'flex',
        position: 'absolute',
        zIndex: '100',
        background: 'rgba( 205, 205, 205, 0.6 )',
      },
      buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -10,
        marginLeft: -12,
      },
    } as any), // TODO: resolve any
);

interface UploadPdf {
  url: string;
  data: FormData;
}

export const EvaluationFinishDialog = (props: EvaluationPhotosDialogProps) => {
  const classes = useStyles();

  const { selectedEvaluation, onClose, actionDone } = props;
  const [uploadedPdf, setUploadedPdf] = useState<UploadPdf | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const uploadRef = useRef<HTMLInputElement>(null);

  const handleSend = async () => {
    setIsLoading(true);
    const res = await adminSendsEvaluationRequest({
      statementId: selectedEvaluation.id,
      formData: uploadedPdf?.data,
    });

    setIsLoading(false);

    if (res.status === 201) {
      onClose();

      if (actionDone) actionDone();
    }
  };

  const handleDocumentUpload = useCallback(
    event => {
      const file = event.target.files[0];

      const url = URL.createObjectURL(file);

      const formData = new FormData();
      formData.append('file', file);

      setUploadedPdf({ url, data: formData });
    },
    [uploadRef],
  );

  const renderCardMedia = () => {
    if (!uploadedPdf) {
      return (
        <img
          alt='upload-document'
          loading='lazy'
          src={`${DefaultPicture}`}
          style={{ width: '100%' }}
          onClick={() => uploadRef?.current?.click()}
        />
      );
    }

    return (
      <div style={{ position: 'relative' }}>
        <IconButton className={classes.remove} onClick={() => setUploadedPdf(null)}>
          <CancelIcon fontSize='small' />
        </IconButton>
        <Document file={{ url: uploadedPdf.url }}>
          <Page pageNumber={1} />
        </Document>
      </div>
    );
  };

  const renderLoadingOverlay = () => {
    if (!isLoading) return <></>;

    return (
      <div className={classes.loadingContent}>
        <CircularProgress className={classes.buttonProgress} size={24} />
      </div>
    );
  };

  return (
    <Dialog aria-labelledby='customized-dialog-title' maxWidth='sm' fullWidth open onClose={onClose}>
      <DialogTitle className={classes.dialogTitle}>Incarca Document</DialogTitle>
      <Card style={{ padding: 10 }}>
        <CardActionArea className={classes.dropZone}>
          <CardMedia>{renderCardMedia()}</CardMedia>
        </CardActionArea>
        <input
          ref={uploadRef}
          accept='application/pdf'
          style={{ display: 'none' }}
          type='file'
          onChange={event => handleDocumentUpload(event)}
        />
      </Card>
      <DialogActions className={classes.actions}>
        <Button color='primary' disabled={isLoading || !uploadedPdf} variant='contained' onClick={() => handleSend()}>
          Trimite
        </Button>
        <Button color='primary' variant='contained' onClick={() => onClose()}>
          Renunta
        </Button>
      </DialogActions>
      {renderLoadingOverlay()}
    </Dialog>
  );
};
