import React, { useState } from 'react';
import { Grid, Tab, Tabs } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { StatementsTable } from '../components/evaluations/table/statements-table';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import { STATEMENT_TYPES } from '../store/statements/statements.constants';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: 20,
    [theme.breakpoints.down('md')]: {
      paddingTop: 50,
      paddingLeft: 15,
      paddingRight: 15,
    },
  },
}));

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
export function StatementsPage(): JSX.Element {
  const classes = useStyles();
  const [value, setValue] = useState<STATEMENT_TYPES>(STATEMENT_TYPES.NOTIFICATION);

  const handleChange = (event: any, newValue: STATEMENT_TYPES) => {
    setValue(newValue)
  };

  return (
    <Grid className={classes.root} container>
      <Grid xs={12} item>
        <TabContext value={value}>
          <Tabs value={value} onChange={handleChange} centered textColor="secondary" indicatorColor="secondary">
            <Tab label="Notificari" value={STATEMENT_TYPES.NOTIFICATION}/>
            <Tab label="Evaluari" value={STATEMENT_TYPES.EVALUATION}/>
            <Tab label="Declaratii" value={STATEMENT_TYPES.STATEMENTS}/>
          </Tabs>
          <TabPanel value={STATEMENT_TYPES.NOTIFICATION}>
            <StatementsTable type={STATEMENT_TYPES.NOTIFICATION} title={'Notificari'} />
          </TabPanel>
          <TabPanel value={STATEMENT_TYPES.EVALUATION} >
            <StatementsTable type={STATEMENT_TYPES.EVALUATION} title={'Evaluari'} />
          </TabPanel>
          <TabPanel value={STATEMENT_TYPES.STATEMENTS}>
            <StatementsTable type={STATEMENT_TYPES.STATEMENTS} title={'Declaratii'} />
          </TabPanel>
        </TabContext>
      </Grid>
    </Grid>
  );
}
