import React, { ReactElement } from 'react';
import { List, ListItem, ListItemAvatar, ListItemText } from '@material-ui/core';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import TimestampIcon from '@material-ui/icons/AccessTime';
import Avatar from '@material-ui/core/Avatar';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import FormatColorResetIcon from '@material-ui/icons/FormatColorReset';
import DescriptionIcon from '@material-ui/icons/Description';
import moment from 'moment';

import { EvaluationDetailProps } from './interfaces';

export const EvaluationAccidentDetails = (props: EvaluationDetailProps) => {
  const { evaluation } = props;

  const { dateTime, location, weatherCondition, roadCondition, description } = evaluation;

  const convertDescription = (description: string | null) => {
    if (description) return description?.replace(/[\[\]"]/gm, '').replace(/\\n/gm, '&#13;');

    return description;
  };

  const getListItem = (value: any, label: string, icon: ReactElement) => {
    return (
      <ListItem style={{ padding: 'unset' }}>
        <ListItemAvatar>
          <Avatar style={{ backgroundColor: 'black' }}>{icon}</Avatar>
        </ListItemAvatar>
        <ListItemText primary={`${label}:`} secondary={<span dangerouslySetInnerHTML={{__html: value ? value : 'N/A'}} />} />
      </ListItem>
    );
  };

  return (
    <List>
      {getListItem(moment(dateTime).format('YYYY-MM-DD HH:mm'), 'Data si ora', <TimestampIcon />)}
      {getListItem(location, 'Locație', <LocationOnIcon />)}
      {getListItem(weatherCondition, 'Condiții meteo', <WbSunnyIcon />)}
      {getListItem(roadCondition, 'Condiții de drum', <FormatColorResetIcon />)}
      {getListItem(convertDescription(description), 'Descriere', <DescriptionIcon />)}
    </List>
  );
};
