import { EVALUATION_STATUSES, NOTIFICATION_STATUSES, STATEMENT_STATUSES } from '../../store/statements/statements.constants';

// TODO: move these interfaces to the appropriate parent file/folder

export const getEvaluationStatusColor = (status: string) => {
  switch (status) {
    case EVALUATION_STATUSES.EVALUATION_INCOMPLETE:
    case STATEMENT_STATUSES.STATEMENT_INCOMPLETE:
    case NOTIFICATION_STATUSES.NOTIFICATION_INCOMPLETE:
      return 'rgba(255,0,0,0.7)';

    case STATEMENT_STATUSES.STATEMENT_FINISHED:
    case EVALUATION_STATUSES.EVALUATION_FINISHED:
    case NOTIFICATION_STATUSES.NOTIFICATION_FINISHED:
      return 'rgb(34,139,34)';

    case EVALUATION_STATUSES.EVALUATION_AWAITING_USER_PAYMENT:
    case EVALUATION_STATUSES.EVALUATION_AWAITING_ADMIN_REVIEW:
    default:
      return 'rgb(255,165,0)';
  }
};

export const getEvaluationStatusLabel = (status: string) => {
  switch (status) {
    case EVALUATION_STATUSES.EVALUATION_INCOMPLETE:
      return 'Evaluarea este incompletă';
    case EVALUATION_STATUSES.EVALUATION_AWAITING_USER_PAYMENT:
      return 'Evaluarea așteaptă utlizatorul';
    case EVALUATION_STATUSES.EVALUATION_AWAITING_ADMIN_REVIEW:
      return 'Evaluarea așteaptă o revizuire';
    case EVALUATION_STATUSES.EVALUATION_FINISHED:
      return 'Evaluarea este finalizata';


    case STATEMENT_STATUSES.STATEMENT_INCOMPLETE:
      return 'Declarația este incompletă';
    case STATEMENT_STATUSES.STATEMENT_AWAITING_ADMIN_PAYMENT:
      return 'Declarația așteaptă plata';
    case STATEMENT_STATUSES.STATEMENT_FINISHED:
      return 'Declarație finalizata';

    case NOTIFICATION_STATUSES.NOTIFICATION_INCOMPLETE:
      return 'Notificarea este incompletă';
    case NOTIFICATION_STATUSES.NOTIFICATION_AWAITING_ADMIN:
      return 'Notificarea așteaptă revizuire';
    case NOTIFICATION_STATUSES.NOTIFICATION_FINISHED:
      return 'Notificare finalizata';
  }
};
