import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createStyles } from '@material-ui/core/styles';
import MaterialTable from '@material-table/core';
import { makeStyles } from '@material-ui/styles';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

import { IStatement } from '../../../store/models';
import { EvaluationDocumentsDialog } from '../dialogs/evaluation-documents-dialog';
import { EvaluationPhotosDialog } from '../dialogs/evaluation-photos-dialog';
import { getStatementsRequest } from '../../../store/statements/statements.api';
import { TABLE_ACTIONS, EVALUATION_STATUSES, STATEMENT_TYPES, NOTIFICATION_STATUSES } from '../../../store/statements/statements.constants';
import { handleRequestError } from '../../../globals/helpers/handle-request-error';
import { NotificationFinishDialog } from '../dialogs/notification-finish-dialog';
import { StatementFinishDialog } from '../dialogs/statement-finish-dialog';
import { EvaluationFinishDialog } from '../dialogs/evaluation-finish-dialog';

import { EvaluationDetailsPanel } from './evaluation-details';
import { getAccidentDateColumn, getEvaluationDateColumn, getEvaluationStatusColumn, getIDColumn } from './columns';

const useStyles = makeStyles(
  () =>
    createStyles({
      root: {
        '& .MuiInputBase-root': {
          fontSize: '13px',
          paddingTop: 20,
        },
      },
      body2: {
        paddingTop: 16,
        fontSize: '1rem',
      },
      status: {
        padding: '7px 10px',
        borderRadius: '25px',
        textAlign: 'center',
        wordWrap: 'break-word',
        width: '60%',
      },
    } as any), // TODO: fix type
);

interface PropTypes {
  title: string;
  type: STATEMENT_TYPES;
}

export const StatementsTable = ({ title, type }: PropTypes) => {
  const classes = useStyles();

  const dispatch = useDispatch();

  const [data, setData] = useState<IStatement[]>([]);

  const [selectedEvaluation, selectEvaluation] = useState<IStatement | null>(null);
  const [action, toggleAction] = useState<TABLE_ACTIONS | null>(null);

  const [loading, setLoading] = useState(false);

  const getStatements = async () => {
    setLoading(true);

    try {
      const { data: statements } = await getStatementsRequest();
      const statementsFiltered = statements.filter(item => item.type === type);
      setData(statementsFiltered);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      const errorMessage = handleRequestError(error, dispatch);
      console.log('Error: ', errorMessage);
    }
  };

  useEffect(() => {
    getStatements();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getTableColumns = () => {
    const result = [];

    result.push(getIDColumn());
    result.push(getEvaluationDateColumn());

    if (type !== STATEMENT_TYPES.EVALUATION) {
      result.push(getAccidentDateColumn());
    }
    result.push(getEvaluationStatusColumn(classes?.status));

    return result;
  };

  const closeDialog = () => {
    toggleAction(null);
    selectEvaluation(null);
  };

  const handleAction = (type: TABLE_ACTIONS, evaluation: IStatement) => {
    toggleAction(type);
    selectEvaluation(evaluation);
  };

  const displayDocumentsAction = () => (row: IStatement) => ({
    icon: () => <InsertDriveFileIcon fontSize='small' />,
    tooltip: 'Documente',
    onClick: () => handleAction(TABLE_ACTIONS.DOCUMENTS, row),
  });

  const displayPhotosAction = () => (row: IStatement) => ({
    icon: () => <PhotoCameraIcon fontSize='small' />,
    tooltip: 'Poze daune',
    onClick: () => handleAction(TABLE_ACTIONS.PHOTOS, row),
    hidden: !row?.injured?.photoProofs?.length,
  });

  const finishEvaluation = () => (row: IStatement) => ({
    icon: () => <CheckCircleOutlineIcon fontSize='small' />,
    tooltip: 'Trimite Evaluarea',
    onClick: (): void => handleAction(TABLE_ACTIONS.EVALUATION_FINISH, row),
    hidden: row?.status !== EVALUATION_STATUSES.EVALUATION_AWAITING_ADMIN_REVIEW,
  });

  const finishNotificationAction = () => (row: IStatement) => ({
    icon: () => <CheckCircleOutlineIcon fontSize='small' />,
    tooltip: 'Confirma notificarea',
    onClick: (): void => handleAction(TABLE_ACTIONS.NOTIFICATION_FINISH, row),
    hidden: row?.status !== NOTIFICATION_STATUSES.NOTIFICATION_AWAITING_ADMIN,
  });

  const getTableActions = () => {
    const actions = [];
    actions.push(displayDocumentsAction());
    actions.push(displayPhotosAction());
    actions.push(finishEvaluation());
    actions.push(finishNotificationAction());
    // TODO: uncomment this in phase 2
    // actions.push(finishEvaluationAction());

    return actions;
  };

  const renderDialogs = () => {
    if (action && selectedEvaluation?.id) {
      switch (action) {
        case TABLE_ACTIONS.DOCUMENTS:
          return <EvaluationDocumentsDialog selectedEvaluation={selectedEvaluation} onClose={closeDialog} />;
        case TABLE_ACTIONS.PHOTOS:
          return <EvaluationPhotosDialog selectedEvaluation={selectedEvaluation} onClose={closeDialog} />;
        case TABLE_ACTIONS.EVALUATION_FINISH:
          return (
            <EvaluationFinishDialog actionDone={() => getStatements()} selectedEvaluation={selectedEvaluation} onClose={closeDialog} />
          );
        case TABLE_ACTIONS.NOTIFICATION_FINISH:
          return (
            <NotificationFinishDialog actionDone={() => getStatements()} selectedEvaluation={selectedEvaluation} onClose={closeDialog} />
          );
        case TABLE_ACTIONS.STATEMENT_FINISH:
          return <StatementFinishDialog selectedEvaluation={selectedEvaluation} onClose={closeDialog} />;

        // TODO: uncomment this in phase 2
        // case EVALUATION_ACTION_TYPES.DONE:
        //   return <EvaluationFinishDialog selectedEvaluation={selectedEvaluation} onClose={closeDialog} />;
        default:
          return <></>;
      }
    }

    return <></>;
  };

  return (
    <>
      <MaterialTable
        actions={getTableActions()}
        columns={getTableColumns()}
        data={data}
        detailPanel={props => {
          return <EvaluationDetailsPanel key={props.rowData.id} evaluation={props.rowData} type={type} />;
        }}
        isLoading={loading}
        options={{
          rowStyle: {
            padding: '0 50px',
          },
          addRowPosition: 'first',
          search: true,
          sorting: true,
          draggable: true,
          pageSize: 10, // TODO: make this global constant
        }}
        style={{ padding: '20px 40px' }}
        title={title}
      />
      {renderDialogs()}
    </>
  );
};
