import React from 'react';
import { List, ListItem, ListItemText } from '@material-ui/core';

import { STATEMENT_TYPES } from '../../../store/statements/statements.constants';

import { EvaluationDetailProps } from './interfaces';

const DAMAGE_ZONES = {
  leftSideCar: 'Partea stângă auto',
  rightSideCar: 'Partea dreaptă auto',
  frontLeft: 'Față stânga',
  frontDoorLeft: 'Ușa față stânga',
  backDoorLeft: 'Ușa spate stânga',
  backLeft: 'Spate stânga',
  frontRight: 'Față dreapta',
  frontDoorRight: 'Ușa față dreapta',
  backDoorRight: 'Ușa spate dreapta',
  backRight: 'Spate dreapta',
};

/**
 *
 * @param {EvaluationDetailProps} props
 * @returns {JSX.Element}
 * @constructor
 */
export const EvaluationQuestionsDetails = (props: EvaluationDetailProps): JSX.Element => {
  const { evaluation, type } = props;

  const { damageTypeQuestion, observationNoteBool, compensationOfferBool, damages } = evaluation;

  const renderDamages = () => {
    //TO DO fix, why damages is an array list, it must to be oneToOne !, and do no not stringify zones !
    const zones = damages?.[0]?.zone;

    const damageField = zones?.length
      ? JSON.parse(zones)
          .map((zone: string) => DAMAGE_ZONES[zone as keyof typeof DAMAGE_ZONES])
          .toString()
      : 'N/A';

    return (
      <>
        <ListItem style={{ padding: 'unset' }}>
          <ListItemText primary='Zone Daune:' secondary={damageField} />
        </ListItem>
        <ListItem style={{ padding: 'unset' }}>
          <ListItemText primary='Descriere Daune:' secondary={damages?.[0]?.description || 'N/A'} />
        </ListItem>
      </>
    );
  };

  return (
    <List>
      <ListItem style={{ padding: 'unset' }}>
        <ListItemText primary='Tipul politiei de asigurare:' secondary={damageTypeQuestion || 'N/A'} />
      </ListItem>
      <ListItem style={{ padding: 'unset' }}>
        <ListItemText primary='Dosar deschis la societate de asigurari:' secondary={observationNoteBool ? 'Da' : 'Nu'} />
      </ListItem>
      {type === STATEMENT_TYPES.EVALUATION && observationNoteBool && (
        <ListItem style={{ padding: 'unset' }}>
          <ListItemText primary='Am primit oferta de despagubire:' secondary={compensationOfferBool ? 'Da' : 'Nu'} />
        </ListItem>
      )}
      {renderDamages()}
    </List>
  );
};
