import { Button, CircularProgress, Dialog, DialogActions, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { createStyles, Theme } from '@material-ui/core/styles';
import React, { useState } from 'react';

import { EvaluationPhotosDialogProps } from '../interfaces';
import { adminFinishNotification } from '../../../store/statements/statements.api';

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      dialogTitle: {
        margin: 0,
        padding: theme.spacing(2),
        backgroundColor: theme.palette.primary.main,
        color: 'white',
      },
      dropZone: {
        width: '100%',
        cursor: 'pointer',
        border: '1px solid lightgray',
        borderStyle: 'dashed',
      },
      preview: {
        color: 'white',
      },
      pdfPage: {
        margin: '0 auto',
        width: '45% !important',
        height: '100% !important',
      },
      remove: {
        position: 'absolute',
        top: '2px',
        right: '2px',
        zIndex: 100,
      },
      actions: {
        margin: '20px auto 30px auto',
      },
      loadingContent: {
        width: '100%',
        height: '100%',
        display: 'flex',
        position: 'absolute',
        zIndex: '100',
        background: 'rgba( 205, 205, 205, 0.6 )',
      },
      buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -10,
        marginLeft: -12,
      },
    } as any), // TODO: resolve any
);

export const NotificationFinishDialog = (props: EvaluationPhotosDialogProps) => {
  const classes = useStyles();

  const { selectedEvaluation, onClose, actionDone } = props;
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleConfirm = async () => {
    setIsLoading(true);
    const res = await adminFinishNotification(selectedEvaluation.id);
    // TODO: after implementing useSWR, call mutate() here
    setIsLoading(false);

    if (res.status === 201) {
      onClose();

      if (actionDone) actionDone();
    }
  };

  const renderLoadingOverlay = () => {
    if (!isLoading) return <></>;

    return (
      <div className={classes.loadingContent}>
        <CircularProgress className={classes.buttonProgress} size={24} />
      </div>
    );
  };

  return (
    <Dialog aria-labelledby='customized-dialog-title' maxWidth='sm' fullWidth open onClose={onClose}>
      <DialogTitle className={classes.dialogTitle}>Confirma finalizarea notificarii!</DialogTitle>
      <DialogActions className={classes.actions}>
        <Button color='primary' disabled={isLoading} variant='contained' onClick={() => handleConfirm()}>
          Confirma
        </Button>
        <Button color='default' variant='contained' onClick={() => onClose()}>
          Renunta
        </Button>
      </DialogActions>
      {renderLoadingOverlay()}
    </Dialog>
  );
};
