import { IStatement } from '../../store/models';
import { EVALUATIONS_DOCUMENTS_LABEL, getAttachmentSrc } from '../../store/statements/statements.constants';

// TODO: move these methods to the appropriate parent file/folder
export const getEvaluationInjuredDocuments = (selectedEvaluation: IStatement | null, token: string) => {
  return [
    {
      title: EVALUATIONS_DOCUMENTS_LABEL.IDENTITY_CARD,
      src: getAttachmentSrc(selectedEvaluation?.injured?.identityCardId, token),
    },
    {
      title: EVALUATIONS_DOCUMENTS_LABEL.DRIVING_LICENSE,
      src: getAttachmentSrc(selectedEvaluation?.injured?.driverLicenseId, token),
    },
    {
      title: EVALUATIONS_DOCUMENTS_LABEL.INSURANCE,
      src: getAttachmentSrc(selectedEvaluation?.injured?.insuranceId, token),
    },
    {
      title: EVALUATIONS_DOCUMENTS_LABEL.CAR_IDENTITY,
      src: getAttachmentSrc(selectedEvaluation?.injured?.vehicleRegistrationCertificateId, token),
    },
  ];
};

export const getEvaluationGuiltyDocuments = (selectedEvaluation: IStatement | null, token: string) => {
  return [
    {
      title: EVALUATIONS_DOCUMENTS_LABEL.INSURANCE,
      src: getAttachmentSrc(selectedEvaluation?.guilty?.insuranceId, token),
    },
  ];
};

export const getEvaluationAccidentDocuments = (selectedEvaluation: IStatement | null, token: string) => {
  return [
    {
      title: EVALUATIONS_DOCUMENTS_LABEL.AMICABLE_FINDING,
      src: getAttachmentSrc(selectedEvaluation?.amicableFindingId, token),
    },
    {
      title: EVALUATIONS_DOCUMENTS_LABEL.POLICE_REPORT,
      src: getAttachmentSrc(selectedEvaluation?.policeReportId, token),
    },
    {
      title: EVALUATIONS_DOCUMENTS_LABEL.OBSERVATION_NOTE,
      src: getAttachmentSrc(selectedEvaluation?.observationNoteId, token),
    },
    {
      title: EVALUATIONS_DOCUMENTS_LABEL.COMPENSATION_OFFER,
      src: getAttachmentSrc(selectedEvaluation?.compensationOfferId, token),
    },
  ];
};

export const getEvaluationPhotos = (selectedEvaluation: IStatement | null, token: string) => {
  return [
    {
      title: EVALUATIONS_DOCUMENTS_LABEL.IDENTITY_CARD,
      src: getAttachmentSrc(selectedEvaluation?.guilty?.identityCardId, token),
    },
    {
      title: EVALUATIONS_DOCUMENTS_LABEL.DRIVING_LICENSE,
      src: getAttachmentSrc(selectedEvaluation?.guilty?.driverLicenseId, token),
    },
    {
      title: EVALUATIONS_DOCUMENTS_LABEL.INSURANCE,
      src: getAttachmentSrc(selectedEvaluation?.guilty?.insuranceId, token),
    },
    {
      title: EVALUATIONS_DOCUMENTS_LABEL.CAR_IDENTITY,
      src: getAttachmentSrc(selectedEvaluation?.guilty?.vehicleRegistrationCertificateId, token),
    },
  ];
};
