import { PayloadAction } from '@reduxjs/toolkit/dist/createAction';

import { createGenericSlice, GenericState, initializeState } from '../store.constants';

import { IUser } from './users.model';

type IUsersSlice = GenericState<IUser[]>;

const initialState: IUsersSlice = initializeState([]);

const usersSlice = createGenericSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers(state: IUsersSlice, action: PayloadAction<IUser[]>) {
      state.data = action.payload;
    },
    clearUsers(state: IUsersSlice) {
      state.data = [];
    },
  },
});

export default usersSlice;

export const { setUsers, clearUsers } = usersSlice.actions;
