import { CircularProgress, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { createStyles } from '@material-ui/core/styles';

import DefaultPicture from '../../assets/pictures/defaultPicture.jpeg';

import { ImageContentProps } from './interfaces';

const useStyles = makeStyles(() =>
  createStyles({
    imgContent: {
      width: '100%',
      position: 'absolute',
    },
    errorContent: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '100%',
      opacity: 0.6,
      background: 'rgba( 205, 205, 205)',
    },
    loadingContent: {
      display: 'flex',
      width: '100%',
      height: '100%',
      opacity: 0.6,
      background: 'rgba( 205, 205, 205)',
    },
    buttonProgress: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -10,
      marginLeft: -12,
    },
  }),
);

/**
 *
 * @param {ImageContentProps} props
 * @returns {JSX.Element}
 * @constructor
 */
export const ImageContent = (props: ImageContentProps): JSX.Element => {
  const classes = useStyles();

  const { title, src, className } = props;

  const [loading, setLoading] = useState<boolean>(true);
  const [source, setSource] = useState<string>(src);
  const [error, setError] = useState<string>('');

  const renderLoadingError = () => {
    if (!error) return <></>;

    return (
      <div className={classes.errorContent}>
        <Typography align='center' color='textSecondary' variant='subtitle2' noWrap>
          {error}
        </Typography>
      </div>
    );
  };

  const renderLoadingOverlay = () => {
    if (!loading) return <></>;

    return (
      <div className={classes.loadingContent}>
        <CircularProgress className={classes.buttonProgress} size={24} />
      </div>
    );
  };

  return (
    <>
      <img
        alt={title}
        className={className}
        src={source}
        onError={() => {
          setSource(DefaultPicture);
          setError('Failed to load');
        }}
        onLoad={() => setLoading(false)}
      />
      {renderLoadingError()}
      {renderLoadingOverlay()}
    </>
  );
};
