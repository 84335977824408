import { createSlice, PayloadAction, SliceCaseReducers, ValidateSliceCaseReducers } from '@reduxjs/toolkit';

export interface GenericState<T> {
  data: T;
  isLoading: boolean;
  error: null | string;
}

export const initializeState = <T>(data: T): GenericState<T> => ({
  data,
  isLoading: false,
  error: null,
});

export const createGenericSlice = <T, Reducers extends SliceCaseReducers<GenericState<T>>>({
  name = '',
  initialState,
  reducers,
}: {
  name: string;
  initialState: GenericState<T>;
  reducers: ValidateSliceCaseReducers<GenericState<T>, Reducers>;
}) => {
  return createSlice({
    name,
    initialState,
    reducers: {
      fetchStart(state) {
        state.isLoading = true;
        state.error = null;
      },
      fetchSuccess(state) {
        state.error = null;
        state.isLoading = false;
      },
      fetchError(state, action: PayloadAction<string>) {
        state.isLoading = false;
        state.error = action.payload;
      },
      /**
       * If you want to write to values of the state that depend on the generic
       * (in this case: `state.data`, which is T), you might need to specify the
       * State type manually here, as it defaults to `Draft<GenericState<T>>`,
       * which can sometimes be problematic with yet-unresolved generics.
       * This is a general problem when working with immer's Draft type and generics.
       */
      ...reducers,
    },
  });
};
